/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "text",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#text",
    "aria-label": "text permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Text"), "\n", React.createElement(_components.p, null, "Utilities for controlling the all typography of an element."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "text={size}")), React.createElement(_components.td, null, "Mixed")))), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Text is similar to ", React.createElement(_components.a, {
    href: "/docs/font-size/"
  }, React.createElement(_components.code, null, "fontSize")), ", except it also specify according ", React.createElement(_components.a, {
    href: "/docs/line-height/"
  }, React.createElement(_components.code, null, "lineHeight")), "."), "\n", React.createElement(_components.p, null, "By extending theme, ", React.createElement(_components.code, null, "text"), " can be used to define all text variants of your application."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=violet"
  }, "<>\n  <template preview>\n    <>\n      {[\n        'xs',\n        'sm',\n        'base',\n        'lg',\n        'xl',\n        '2xl',\n        '3xl',\n        '4xl',\n        '5xl',\n        '6xl',\n        '7xl',\n        '8xl',\n        '9xl',\n      ].map((size) => (\n        <x.dl\n          key={size}\n          display=\"flex\"\n          alignItems=\"baseline\"\n          color=\"violet-600\"\n          overflow=\"hidden\"\n        >\n          <x.dt w={16} flexShrink={0} text=\"sm\" opacity={0.8} fontFamily=\"mono\">\n            {size}\n          </x.dt>\n          <x.dd text={size} fontWeight=\"medium\">\n            Computers have lots of memory but no imagination.\n          </x.dd>\n        </x.dl>\n      ))}\n    </>\n  </template>\n  <x.p text=\"xs\">Computers have lots ...</x.p>\n  <x.p text=\"sm\">Computers have lots ...</x.p>\n  <x.p text=\"base\">Computers have lots ...</x.p>\n  <x.p text=\"lg\">Computers have lots ...</x.p>\n  <x.p text=\"xl\">Computers have lots ...</x.p>\n  <x.p text=\"2xl\">Computers have lots ...</x.p>\n  <x.p text=\"3xl\">Computers have lots ...</x.p>\n  <x.p text=\"4xl\">Computers have lots ...</x.p>\n  <x.p text=\"5xl\">Computers have lots ...</x.p>\n  <x.p text=\"6xl\">Computers have lots ...</x.p>\n  <x.p text=\"7xl\">Computers have lots ...</x.p>\n  <x.p text=\"8xl\">Computers have lots ...</x.p>\n  <x.p text=\"9xl\">Computers have lots ...</x.p>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the space between elements at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "text={{ md: \"xl\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "text=\"xl\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.p text={{ xs: 'sm', md: 'xl' }}>{/* ... */}</x.p>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", React.createElement(_components.h3, {
    id: "texts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#texts",
    "aria-label": "texts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Texts"), "\n", React.createElement(_components.p, null, "If you'd like to customize your values for texts, use the ", React.createElement(_components.code, null, "theme.texts"), " section of your theme. All typography utilities are authorized, not only ", React.createElement(_components.code, null, "fontSize"), " and ", React.createElement(_components.code, null, "lineHeight"), ". You can literally define all your texts style using this utility, including spacing properties like ", React.createElement(_components.code, null, "marginBottom"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-diffjs"
  }, "  // theme.js\n  export const theme = {\n    texts: {\n      title: {\n        fontSize: '30px',\n        lineHeight: '45px',\n        fontWeight: 'bold'\n      },\n      hint: {\n        fontSize: 10,\n        lineHeight: 1.4,\n        color: 'gray-300',\n      }\n    },\n  }\n")), "\n", React.createElement(_components.p, null, "If you don't want to customize it, a set of ", React.createElement(_components.code, null, "texts"), " is already defined in default theme:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const defaultTheme = {\n  // ...\n  texts: {\n    xs: { fontSize: 'xs', lineHeight: 'xs' },\n    sm: { fontSize: 'sm', lineHeight: 'sm' },\n    default: { fontSize: 'default', lineHeight: 'default' },\n    lg: { fontSize: 'lg', lineHeight: 'lg' },\n    xl: { fontSize: 'xl', lineHeight: 'xl' },\n    '2xl': { fontSize: '2xl', lineHeight: '2xl' },\n    '3xl': { fontSize: '3xl', lineHeight: '3xl' },\n    '4xl': { fontSize: '4xl', lineHeight: '4xl' },\n    '5xl': { fontSize: '5xl', lineHeight: '5xl' },\n    '6xl': { fontSize: '6xl', lineHeight: '6xl' },\n    '7xl': { fontSize: '7xl', lineHeight: '7xl' },\n    '8xl': { fontSize: '8xl', lineHeight: '8xl' },\n    '9xl': { fontSize: '9xl', lineHeight: '9xl' },\n  },\n}\n")), "\n", React.createElement(_components.p, null, "Values are defined in ", React.createElement(_components.code, null, "fontSizes"), " and ", React.createElement(_components.code, null, "lineHeights"), " theme section. It means if you modify a ", React.createElement(_components.code, null, "fontSize"), " it will automatically reflects change on ", React.createElement(_components.code, null, "text"), " and ", React.createElement(_components.code, null, "fontSize"), " utility."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
